<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="400">
      <v-alert v-if="$_getLocale() != 'en'" type="info">Due to limited resources, we only able to provide basic English version management tools for educators/parents/guardians at this moment. Thanks for your understanding and supports.</v-alert>
      <v-card-title>My Account</v-card-title>
      <AError
        v-if="keyCheckApi.isError"
        :api="keyCheckApi"
        :callbackReset="() => (keyCheckApi.isError = false)"
      ></AError>
      <AError
        v-if="editApi.isError"
        :api="editApi"
        :callbackReset="() => (editApi.isError = false)"
      ></AError>
      <AError
        v-if="loginApi.isError"
        :api="loginApi"
        :callbackReset="() => (loginApi.isError = false)"
      ></AError>
      <AError
        v-if="countryApi.isError"
        :api="countryApi"
        :callbackReset="() => (countryApi.isError = false)"
      ></AError>
      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="form-content text-center">
          <v-form ref="formMobile" v-model="validMobile" lazy-validation>
            <v-row class="px-0 pt-3">
              <v-col cols="4">
                <v-select
                  :items="countries"
                  outlined
                  v-model="countryCode"
                  :item-text="countryNamed"
                  item-value="countryCode"
                  :loading="countryApi.isLoading"
                >
                  <template v-slot:selection="{ item }">
                    <v-img
                      contain
                      max-width="30"
                      max-height="40"
                      :src="
                        require('@/assets/Flag/' + item.countryCode + '.svg')
                      "
                    ></v-img>
                  </template>
                </v-select>
              </v-col>
              <v-col class="mx-0 px-0" cols="8">
                <TextField
                  :prefix="
                    selectedCountry && selectedCountry.phoneCode
                      ? selectedCountry.phoneCode
                      : null
                  "
                  :value="mobile"
                  label="Mobile Number"
                  :rules="mobileRules"
                  type="text"
                  @input="mobile = $event"
                ></TextField>
              </v-col>
            </v-row>
            <div class="text-center mb-3">
              <v-btn width="75%" rounded color="primary" dark @click="next()">
                Continue
              </v-btn>
            </div>
            <router-link class="mb-3" :to="{name:'PageAccountForgetPassword'}">Forgot password?</router-link>
            <!-- <div>No account? <a @click="toRegister()">Register</a></div> -->
            <!-- <div class="text-center pt-2">
              <v-divider></v-divider>
            </div> -->
            <!-- <div class="pt-2">
              <div class="text-center pt-2">
                <v-btn
                  block
                  color="red darken-4"
                  @click="clickGoogle()"
                  class="white--text"
                >
                  <v-icon left dark> mdi-google </v-icon>
                  Google
                </v-btn>
              </div>
              <div class="text-center pt-2">
                <v-btn
                  block
                  color="blue darken-1"
                  @click="clickSocial()"
                  class="white--text"
                >
                  <v-icon left dark> mdi-facebook </v-icon>
                  Facebook
                </v-btn>
              </div>
              <div class="text-center pt-2">
                <v-btn
                  block
                  color="black"
                  @click="clickSocial()"
                  class="white--text"
                >
                  <v-icon left dark> mdi-apple </v-icon>
                  Apple
                </v-btn>
              </div>
            </div> -->
          </v-form>
        </div>
        <div v-if="step == 2" class="form-content text-center">
          <v-form ref="formPassword" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <TextField
                :value="mobileNumber"
                label="Mobile Number"
                :rules="mobileRules"
                type="text"
                @input="mobileNumber = $event"
              ></TextField>
            </div>
            <div class="text-center">
              <v-text-field
                v-model="password"
                block
                label="Password"
                outlined
                clearable
                :rules="passwordRules"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              ></v-text-field>
            </div>
            <div class="text-center mb-3">
              <v-btn width="75%" rounded color="primary" dark @click="login()">
                Log In
              </v-btn>
            </div>
            <router-link class="mb-3" :to="{name:'PageAccountForgetPassword'}">Forgot password?</router-link>
          </v-form>
        </div>
        <div v-if="step == 3 && isCheckedKey" class="form-content text-center">
          <div>Choose the type of your account:</div>

          <div
            class="text-center pt-2"
            v-for="account in accountTypes"
            :key="account.value"
          >
            <v-btn
              block
              rounded
              outlined
              color="primary"
              dark
              @click="selectAccountType(account)"
            >
              {{ account.value }}
            </v-btn>
          </div>
        </div>
        <div v-if="step == 3 && !isCheckedKey" class="form-content text-center">
          <div class="text-center pt-2">
            <v-progress-circular
              :size="80"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import TextField from "../../components/Form/TextField.vue";
export default {
  components: {
    TextField,
  },
  computed: mapState({
    invitationToken: (state) => state.invitationToken.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    validMobile: true,
    valid: true,
    step: 1,
    name: null,
    mobile: null,
    otp: null,
    confirmPassword: null,
    password: null,
    show1: false,
    show: false,
    isClickSocial: false,
    isCheckedKey: false,
    accountType: null,
    userType: null,
    selectedCountry: null,
    countries: [],
    countryCode: "MYS",
    account: {},
    mobileRules: [],
    passwordRules: [],
    mobileNumber: null,
    accountTypes: [
      {
        id: 1,
        value: "Student",
      },
      {
        id: 2,
        value: "Guardian / Parent",
      },
      {
        id: 2,
        value: "Teacher",
      },
    ],
    //BOC:[api]
    loginApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    keyCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    countryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Home",
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: "Login",
      to: { name: "PageMainLogin" },
      exact: true,
    });
    //EOC
    this.countryApi.method = "GET";
    this.countryApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      "en" +
      // this.$_getLocale() +
      "/data/country/";

    this.countryApi.callbackReset = () => {
      this.countryApi.isLoading = true;
      this.countryApi.isError = false;
    };

    this.countryApi.callbackError = (e) => {
      this.countryApi.isLoading = false;
      this.countryApi.isError = true;
      this.countryApi.error = e;
    };

    this.countryApi.callbackSuccess = (resp) => {
      this.countryApi.isLoading = false;
      this.countries = resp;
      this.selectedCountry = this.$_.find(this.countries, {
        countryCode: this.countryCode,
      });
    };

    this.keyCheckApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/checkKey";
    this.keyCheckApi.callbackReset = () => {
      this.keyCheckApi.isLoading = true;
      this.keyCheckApi.isError = false;
    };

    this.keyCheckApi.callbackError = (e) => {
      this.keyCheckApi.isLoading = false;
      this.keyCheckApi.isError = true;
      this.keyCheckApi.error = e;
      this.step = 1;
    };

    this.keyCheckApi.callbackSuccess = (resp) => {
      this.keyCheckApi.isLoading = false;

      this.account = resp.Account;
      if (resp.isLogin) {
        this.$store.commit("updateAuth", resp);
        if (resp.Account.type == "educator") {
          if(this.invitationToken && this.invitationToken.token){
          this.$router.push({ name: "PageEducatorInstitutionTeacherInvite",query:{token:this.invitationToken.token} });
        }else{
          this.$router.push({ name: "PageEducatorDashboard" });
        }
        } else if (resp.Account.type == "family") {
          this.$router.push({ name: "PageFamilyDashboard" });
        } else if (resp.Account.type == "moderator") {
          this.$router.push({ name: "PageModeratorDashboard" });
        }
      } else {
        this.isCheckedKey = true;
      }
    };

    this.loginApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/login";
    this.loginApi.callbackReset = () => {
      this.loginApi.isLoading = true;
      this.loginApi.isError = false;
    };

    this.loginApi.callbackError = (e) => {
      this.loginApi.isLoading = false;
      this.loginApi.isError = true;
      this.loginApi.error = e;
    };

    this.loginApi.callbackSuccess = (resp) => {
      this.loginApi.isLoading = false;
      if (resp.Account.type == "educator") {
        this.$store.commit("updateAuth", resp);
        if(this.invitationToken && this.invitationToken.token){
          this.$router.push({ name: "PageEducatorInstitutionTeacherInvite",query:{token:this.invitationToken.token} });
        }else{
          this.$router.push({ name: "PageEducatorDashboard" });
        }
      } else if (resp.Account.type == "family") {
        this.$store.commit("updateAuth", resp);
        this.$router.push({ name: "PageFamilyDashboard" });
      } else if (resp.Account.type == "moderator") {
        console.log(resp)
        this.$store.commit("updateAccount", resp.UserAccount);
        delete resp.Group;
        this.$store.commit("updateAuth", resp);
        this.$router.push({ name: "PageModeratorDashboard" });
      }
    };
    this.editApi.url =
      this.$api.servers.sso + "/api/v1/" + this.$_getLocale() + "/account/edit";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = (resp) => {
      this.editApi.isLoading = false;
      this.$store.commit("updateAuth", resp);
      if (resp.Account.type == "educator") {
        if(this.invitationToken && this.invitationToken.token){
          this.$router.push({ name: "PageEducatorInstitutionTeacherInvite",query:{token:this.invitationToken.token} });
        }else{
          this.$router.push({ name: "PageEducatorDashboard" });
        }
      } else {
        this.$router.push({ name: "PageFamilyDashboard" });
      }
      console.log(resp);
    };
  },
  watch: {
    mobile: function () {
      this.mobileRules = [];
    },
    password: function () {
      this.passwordRules = [];
    },
    mobileNumber: function () {
      this.mobileRules = [];
    },
    countryCode: function (val) {
      this.countryCode = val;
      this.selectedCountry = this.$_.find(this.countries, {
        countryCode: this.countryCode,
      });
    },
  },
  mounted() {
    this.$api.fetch(this.countryApi);
    if (this.$route.query.key) {
      this.isClickSocial = true;
      this.step = 3;
      this.checkKey();
    }
    if(this.$route.query.m && this.$route.query.p){
      this.mobileNumber = this.$route.query.m
      this.password = this.$route.query.p
      this.step = 2
      this.login()
    }
    else if(this.$route.query.m){
      this.mobileNumber = this.$route.query.m
      this.step = 2
    }
  },
  methods: {
    clickGoogle() {
      const googleLoginURL =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/account/auth/google/register";
      window.open(googleLoginURL, "_self");
    },
    increase() {
      if (this.isClickSocial) {
        this.$router.push({ name: "PageTestSchoolDashboard" });
        // this.step = 6
      } else {
        this.step++;
      }
    },
    next() {
      this.mobileRules = [
        () =>
          (!!this.mobile &&
            this.mobile.length >= 7 &&
            this.mobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.mobile)) ||
          "The Mobile number must be an integer number",
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formMobile.validate()) {
          self.mobileNumber = self.selectedCountry.phoneCode + self.mobile;
          self.step++;
          self.mobileRules = [];
        } else {
          self.loginApi.callbackError("Please check the form.");
        }
      });
    },
    checkKey() {
      this.keyCheckApi.params = {
        key: this.$route.query.key,
      };
      this.$api.fetch(this.keyCheckApi);
    },
    toRegister() {
      this.$router.push({ name: "PageMainRegister" });
    },
    login() {
      this.mobileRules = [
        () => !!this.mobileNumber || "Mobile number is required",
        () =>
          Number.isInteger(Number(this.mobileNumber)) ||
          "The Mobile number must be an integer number",
      ];
      this.passwordRules = [(v) => !!v || "Password is required"];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formPassword.validate()) {
          self.loginApi.params = {
            password: self.password,
            mobile: self.mobileNumber,
          };
          self.$api.fetch(self.loginApi);
        } else {
          self.loginApi.callbackError("Please check the form.");
        }
      });
    },
    clickSocial() {
      this.isClickSocial = true;
      // this.step = 3
      this.$router.push({ name: "PageTestSchoolDashboard" });
    },
    decrease() {
      this.step--;
    },
    selectAccountType(type) {
      if (type.value == "Teacher") {
        this.accountType = "educator";
      } else {
        this.accountType = "family";
      }
      if (type.value == "Student") {
        this.userType = "child";
      } else {
        this.userType = "adult";
      }
      this.updateAccount();
    },
    updateAccount() {
      this.editApi.params = {
        id: this.account.id,
        accountType: this.accountType,
        userType: this.userType,
      };
      this.$api.fetch(this.editApi);
    },
    countryNamed: (item) => `(${item.phoneCode}) ${item.name}`,
  },
};
</script>
  <style scoped>
.form-content {
  width: 300px !important;
}
</style>